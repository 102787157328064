import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>


            <Col
              lg={{ span: '4', order: 3 }}
              md={6}
              xs='12'
              className="d-flex justify-content-center justify-content-lg-end justify-content-md-center "
            >
              {/* Social Media Icons */}
              <ul className="list-unstyled d-flex ">
                <li className="ms-2">
                  <a href="https://www.facebook.com/TehutiOfficialPage/" target="_blank" rel="noreferrer">
                    <i className='bx bxl-facebook fs-4 p-1 rounded-circle text-white ' style={{ backgroundColor: "#0866FF" }} />
                  </a>
                </li>
                <li className="ms-2">
                  <a href="https://twitter.com/Tehuti" target="_blank" rel="noreferrer">
                    <i className='bx bx-x fs-4 p-1 rounded-circle bg-black text-white' />
                  </a>
                </li>
                <li className="ms-2">
                  <a href="https://tiktok.com/Tehuti" target="_blank" rel="noreferrer" className="roudend-circle">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.<path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" /></svg>
                  </a>
                </li>
                <li className="ms-2">
                  <a href="https://www.linkedin.com/company/tehuti/?originalSubdomain=ke" target="_blank" rel="noreferrer">
                    <i className='bx bxl-linkedin fs-4 p-1 rounded-circle text-white'
                      style={{ backgroundColor: "#0077B5" }}
                    />
                  </a>
                </li>
                <li className="ms-2">
                  <a href="https://www.instagram.com/tehuti/" target="_blank" rel="noreferrer">
                    <i className='bx bxl-instagram fs-4 p-1 rounded-circle text-white'
                      style={{ backgroundColor: "#F56040" }} />
                  </a>
                </li>
                <li className="ms-2">
                  <a href="https://www.youtube.com/channel/UCGsUrqRsbIc-7Y55D--SBgwcfg/" target="_blank" rel="noreferrer">
                    <i className='bx bxl-youtube fs-4 p-1 rounded-circle bg-white text-danger' />
                  </a>
                </li>
                <li className="ms-2">
                  <a href="https://api.whatsapp.com/send/?phone=254708315284&text=0&type=phone_number&app_absent=0" target="_blank" rel="noreferrer">
                    <i className='bx bxl-whatsapp fs-4 p-1 rounded-circle bg-success text-white' />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
