import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form, Modal, Spinner, InputGroup } from "react-bootstrap"
import toast from "react-hot-toast";
import Select from "react-select";
import { Link } from "react-router-dom"

import * as setup from "models/setup";
import * as auth from "models/auth"

const AuthPop = props => {
  const [levelTypes, setLevelTypes] = useState([])
  const [levels, setLevels] = useState([])
  const [selectedUserType, setSelecteduserType] = useState()
  const [selectedLevelType, setSelectedLevelType] = useState(null)
  const [selectedLevel, setSelectedLevel] = useState(null)

  const [showPword, setShowPword] = useState(false)
  const [isLoading, setIsLoading] = useState()

  const toggleShowPass = () => setShowPword(!showPword)

  const closeModal = () => {
    props.setShowModal(false)
    props.setShowSignUp(false)
    setIsLoading(false)
  }
  const handleSelectLevelType = (selectedLevelType) => {
    setSelectedLevelType(selectedLevelType)
    //getAllSubCategories(selectedCategory?.label)
  }

  const handleSelectLevel = (selectedLevel) => {
    setSelectedLevel(selectedLevel)
  }

  const handleLogin = (event) => {
    setIsLoading(true)
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    const payload = {
      userEmail: form.userEmail.value,
      password: form.password.value
    }
    auth.login(payload)
      .then(resp => {
        const data = resp.profile
        setIsLoading(false)
        {
          data?.userType === "student" ? (localStorage.setItem("teutiUser", JSON.stringify(data)),
            props.setUpdateLocalStorage(true),
            closeModal()) : (toast.error("You are not allowed to use this portal"))
        }

      })
      .catch(err => {
        setIsLoading(false)
        toast.error(err?.response?.data?.message)
      })
  }

  const handleRegister = (event) => {
    setIsLoading(true)
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    const payload = {
      fullName: form.fullName.value,
      userEmail: form.userEmail.value,
      password: form.password.value,
      level: selectedLevel?.value,
      levelType: selectedLevelType?.value,
      phoneNumber: form.phoneNumber.value,
      password: form.password.value,
      location: form.location.value
    }
    auth.register(payload)
      .then(resp => {
        const data = resp.profile
        setIsLoading(false)
        closeModal()
      })
      .catch(err => {
        setIsLoading(false)
        toast.error(err?.response?.data?.message)
      })
  }
  const getAllLevelTypes = (params) => {
    const profile = JSON.parse(localStorage.getItem("authUser"));
    const token = profile?.token;
    setup.getAllLevelTypes(params, token)
      .then(resp => {
        const data = resp.levelTypes
        setLevelTypes([
          {
            label: "Level Tpyes",
            options: data.map(item => ({
              label: item?.levelTypeName,
              value: item?.levelTypeName,
              levels: item?.levels
            }))
          }
        ])
      }).catch(err => {
        toast.error(err?.response?.data?.message)
      })
  }
  useEffect(() => {
    getAllLevelTypes()
  }, [])

  useEffect(() => {
    console.log(selectedLevelType?.levels)
    setLevels([
      {
        label: "Level ",
        options: selectedLevelType?.levels.map(item => ({
          label: item?.levelName,
          value: item?.levelName,
        }))
      }
    ])
  }, [selectedLevelType])

  return (
    <div>
      <Modal
        centered
        show={props.showModal}
        onHide={() => closeModal()}>
        <Modal.Header closeButton>
          <Modal.Title>
            Log in or sign up
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.showSignUp ? (
            <Form
              onSubmit={handleRegister}
              className=""
            >
              <Form.Group className="mb-3" controlId="fullName">
                <Form.Label>Full Name</Form.Label>
                <InputGroup className="form-control-lgm">
                  <Form.Control
                    type="text"
                    placeholder="e.g. John Doe"
                    required
                  />
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  Invalid
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="userEmail">
                <Form.Label>User Email</Form.Label>
                <InputGroup className="form-control-lgm">
                  <Form.Control
                    type="email"
                    placeholder="e.g. johndoe@email.com"
                    required
                  />
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  Invalid Email
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="phoneNumber">
                <Form.Label>Phone Number</Form.Label>
                <InputGroup className="form-control-lgm">
                  <Form.Control
                    type="number"
                    placeholder="e.g. 0712345678"
                    required
                  />
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  Invalid
                </Form.Control.Feedback>
              </Form.Group>


              <Form.Group className="mb-3" controlId="levelType">
                <Form.Label>
                  Level Type
                </Form.Label>
                <Select
                  value={selectedLevelType}
                  onChange={handleSelectLevelType}
                  options={levelTypes}
                />
              </Form.Group>
              {selectedLevelType?.value === undefined ? null : (<Form.Group className="mb-3" controlId="level">
                <Form.Label>
                  Level
                </Form.Label>
                <Select
                  value={selectedLevel}
                  onChange={handleSelectLevel}
                  options={levels}
                />
              </Form.Group>)}
              <Form.Group className="mb-3" controlId="location">
                <Form.Label>Location</Form.Label>
                <InputGroup className="form-control-lgm">
                  <Form.Control
                    type="text"
                    placeholder="e.g. Nairobi"
                    required
                  />
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  Invalid
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="password">
                <Form.Label>Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showPword ? "text" : "password"}
                    placeholder="******"
                    required
                    className=""
                  />
                  <button
                    type="button"
                    onClick={e => toggleShowPass(e)}
                    className="btn btn-primary shadow-none "
                  >
                    {showPword ? (
                      <i className="fas fa-eye fs-4" />
                    ) : (
                      <i className="fas fa-eye-slash fs-4" />
                    )}
                  </button>
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  Invalid Password
                </Form.Control.Feedback>
              </Form.Group>

              <div className="my-4">
                <Link to="/forgot-password" className="text-primary ">
                  Forgot Password
                </Link>
              </div>

              <div className="mt-4 d-grid ">
                <button
                  className="btn btn-primary btn-block btn-lg rounded-0"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{" "}
                      Processing...
                    </>
                  ) : (
                    "Create Account"
                  )}
                </button>
              </div>
              <div className="d-flex justify-content-center mb-3 mt-2">
                <a className="text-primary"
                  onClick={() => props.setShowSignUp(true)}>
                  Already have an account?
                </a>
              </div>
            </Form>
          ) : (
            <Form
              onSubmit={handleLogin}
              className=""
            >
              <Form.Group className="mb-3" controlId="userEmail">
                <Form.Label>User Email</Form.Label>
                <InputGroup className="form-control-lgm">
                  <Form.Control
                    type="email"
                    placeholder="e.g. johndoe@email.com"
                    required0
                  />
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  Invalid Email
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3" controlId="password">
                <Form.Label>Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showPword ? "text" : "password"}
                    placeholder="******"
                    required
                    className=""
                  />
                  <button
                    type="button"
                    onClick={e => toggleShowPass(e)}
                    className="btn btn-primary shadow-none "
                  >
                    {showPword ? (
                      <i className="fas fa-eye fs-4" />
                    ) : (
                      <i className="fas fa-eye-slash fs-4" />
                    )}
                  </button>
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  Invalid Password
                </Form.Control.Feedback>
              </Form.Group>

              <div className="my-4">
                <Link to="/forgot-password" className="text-primary ">
                  Forgot Password
                </Link>
              </div>

              <div className="mt-4 d-grid ">
                <button
                  className="btn btn-primary btn-block btn-lg rounded-0"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{" "}
                      Processing...
                    </>
                  ) : (
                    "Login"
                  )}
                </button>
              </div>
              <div className="d-flex justify-content-center mb-3 mt-2">
                <a className="text-primary"
                  onClick={() => props.setShowSignUp(true)}>
                  Create an account.
                </a>
              </div>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </div>
  )
}

AuthPop.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  showSignUp: PropTypes.bool,
  setShowSignUp: PropTypes.fun
}

export default AuthPop