import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from "react-bootstrap"
import { SyncLoader } from "react-spinners"
import toast from "react-hot-toast";

import * as setup from "models/setup";

import FileCard from "components/FileCard"
import FileViewer from "components/FileViewer";

const Home = prop => {
  const [userProfile, setUserProfile] = useState(null)
  const [contents, setContents] = useState([])
  const [listedContent, setListedContent] = useState([])
  const [listedSubjects, setListedSubjects] = useState([])
  const [listedTopics, setListedTopics] = useState([])
  const [listedSubTopics, setListedSubTopics] = useState([])
  const [listedSession, setListedSession] = useState([])
  const [query, setQuery] = useState({})

  const [selectedSubject, setSelectedSubject] = useState("")
  const [selectedTopic, setSelectedTopic] = useState("")
  const [selectedSubTopic, setSelectedSubTopic] = useState("")
  const [selectedSession, setSelectedSession] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const [downloadReady, setDownloadReady] = useState(true)
  const [signTrigger, setSignTrigger] = useState(true)
  const [selected, setSelected] = useState(null)
  const [showFileViewer, setShowFileViewer] = useState(false)
  const [activeFileTab, setActiveFileTab] = useState({
    name: "All", value: "All"
  })
  const [activeSubjectTab, setActiveSubjectTab] = useState("")

  const fileTabs = [
    { name: "Docs", value: "docs", icon: "fas fa-file" },
    { name: " Audios", value: "audios", icon: "fas fa-volume-up" },
    { name: " Videos", value: "videos", icon: "fas fa-video" },
    { name: "Lectures", value: "lectures", icon: "fas fa-stream" },
    { name: "Practicals ", value: "practicals", icon: "fas fa-video" },
    { name: "Discussions ", value: "discussion", icon: "fas fa-users" },


  ]
  function groupTopicsByUnitAndSession(topics) {
    return topics.reduce((groupedUnits, topic) => {
      // Find or create the unit group
      let unitGroup = groupedUnits.find(group => group.unit === topic.unit);

      if (!unitGroup) {
        unitGroup = {
          unit: topic.unit,
          unitId: topic.unitId,
          sessions: []
        };
        groupedUnits.push(unitGroup);
      }

      // Find or create the session group within the unit
      let sessionGroup = unitGroup.sessions.find(session => session.sessionName === topic.sessionName);

      if (!sessionGroup) {
        sessionGroup = {
          sessionName: topic.sessionName,
          topics: []
        };
        unitGroup.sessions.push(sessionGroup);
      }

      // Add the topic to the session group
      sessionGroup.topics.push(topic);

      return groupedUnits;
    }, []);
  }

  function groupTopicsByUnit(topics) {
    const grouped = topics.reduce((acc, topic) => {
      // Find or create the unit entry in the accumulator array
      let unitEntry = acc.find(entry => entry.unit === topic.unit);

      // If the unit entry doesn't exist, create it
      if (!unitEntry) {
        unitEntry = { unit: topic.unit, unitId: topic.unitId, topics: [] };
        acc.push(unitEntry);
      }

      // Add the topic to the topics array of the found or created unit entry
      unitEntry.topics.push(topic);

      return acc;
    }, []);

    return grouped;
  }




  const getAllTopics = () => {
    setup.getAllTopics(`level=${prop.selectedLevel}`)
      .then(resp => {
        const data = resp.topics
        setListedSubjects(groupTopicsByUnit(data))

      }).catch(err => {
      })
  }

  const getAllSubTopics = () => {
    setup.getAllSubTopics(`topic=${selectedTopic}`)
      .then(resp => {
        const data = resp?.subTopics
        setListedSubTopics(data)
      }).catch(err => {

      })
  }

  useEffect(() => {
    setActiveSubjectTab("")
    setSelectedTopic("")
    setListedSession([])
    setListedTopics([])
    const filterbyLevel = prop?.selectedLevel === "" ?
      "" : `level=${prop.selectedLevel}`
    const filterbyFile = activeFileTab.value === "All" ? "" : activeFileTab?.value === 'doc' ? `fileType=pdf&fileType=powerpoint` : `fileType=${activeFileTab.value}`
    const filterBySubject = selectedSubject === "" ? "" : `unitName=${selectedSubject}`
    const preq = filterbyLevel + `&${filterbyFile}`
    setQuery(preq)
  }, [prop?.selectedLevel, activeFileTab])


  const getAllContents = () => {
    setup.getAllContents(`subTopic=${selectedSubTopic}`)
      .then(resp => {
        const data = resp?.contents
        setListedContent(resp?.contents)
        setIsLoaded(true)

      }).catch(err => {

      })
  }
  useEffect(() => {
    if (selectedSubTopic) {
      console.log(listedContent)
      getAllContents()
    }
  }, [selectedSubTopic])

  useEffect(() => {
    if (activeSubjectTab) {
      const m = listedSubjects.find((item => item?.unit === activeSubjectTab))
      setListedTopics(m?.topics)
      console.log(m)
    }
  }, [activeSubjectTab])

  useEffect(() => {
    const profile = JSON.parse(localStorage.getItem("teutiUser"))
    setUserProfile(profile)
    if (profile) {
      setSignTrigger(false)
    } else {
      setSignTrigger(true)
    }
  }, [])
  useEffect(() => {
    const profile = JSON.parse(localStorage.getItem("teutiUser"))
    setUserProfile(profile)
    if (profile) {
      setSignTrigger(false)
    } else {
      setSignTrigger(true)
    }
  }, [prop.updateLocalStorage])

  useEffect(() => {
    if (prop.selectedLevel) {
      setListedContent([]),
        getAllTopics()
    }
  }, [prop.selectedLevel])

  useEffect(() => {

    setListedContent([])
    if (prop.selectedTopics) {
      setListedTopics(prop.selectedTopics)

    }
  }, [prop.selectedTopics])

  useEffect(() => {
    if (selectedTopic) {
      getAllSubTopics()
    }
  }, [selectedTopic])
  return (
    <div className="page-content bg-white">
      <Row className="bg-white">
        {!userProfile && (
          <Col md={3} lg={2} className="d-md-none">

            {listedSubjects.length > 0 && (<div className="d-flex flex-row flex-wrap bg-light p-2 ">
              {/* <div className="d-flex justify-content-centerd align-items-center me-1 ">
                Learning Areas:
              </div> */}
              Learning Areas:
              {/* <div className="d-flex flex-row"> */}
              {listedSubjects.map(((item, index) => (
                <div
                  key={index}
                  className={`d-flex align-items-center justify-content-center border-3 btn border-0  btn-sm me-md-3 ms-1
                  
                  bg-${activeSubjectTab === item?.unit ? "secondary" : ""} 
                  text-${activeSubjectTab === item.unit ? "white" : "secondary"}
                  `}
                  href={`#${item.name}`}
                  onClick={() => { setListedSubTopics([]), setActiveSubjectTab(item?.unit) }}
                >
                  <i className={`${item?.icon}`}></i>
                  <h6 className={`mx-1 my-0 font-size-12`} >
                    {item.unit}
                  </h6>
                </div>


              )))}
            </div>
            )}
          </Col>
        )}
        {!userProfile && (<Col md={3} lg={2} className="d-none d-md-block">
          {listedSubjects.length > 0 && (<div className="d-flex flex-column bg-light p-2">
            <div className="d-flex justify-content-center align-items-center me-1 ">
              Learning Areas </div> {listedSubjects.map(((item, index) => (
                <div
                  key={index}
                  className={`d-flex align-items-center justify-content-center border-3 btn border-0  btn-sm me-md-3
                  
                  bg-${activeSubjectTab === item?.unit ? "secondary" : ""} 
                  text-${activeSubjectTab === item.unit ? "white" : "secondary"}
                  `}
                  href={`#${item.name}`}
                  onClick={() => { setListedSubTopics([]), setActiveSubjectTab(item?.unit) }}
                >
                  <i className={`${item?.icon}`}></i>
                  <h6 className={`mx-1 my-0 `} >
                    {item.unit}
                  </h6>
                </div>


              )))}
          </div>
          )}
        </Col>)}


        <Col className="d-flex pb-5  " style={{
          height: "70vh"
        }}>
          {isLoading ?
            <div className="d-flex p-5 my-5 justify-content-center">
              <SyncLoader
                color='#082F1A'
                size={20}
                margin={10}
              />

            </div> :
            <div className=" w-100"
            >
              <Row className="mt-1">
                <Col md={12} className="d-flex justify-content-between justify-content-md-start">
                  {listedSession.map((item, index) => (
                    <Col
                      xs={4}
                      key={index}
                      className={`d-flex align-items-center col col-md-2 justify-content-center border-3 btn border-0 btn-sm me-md-3
                  
                  bg-${selectedSession === item?.sessionName ? "warning" : "white"} 
                  text-${selectedSession === item?.sessionName ? "white" : "warning"}
                  `}
                      href={`#${item}`}
                      onClick={() => setSelectedSession(item?.sessionName)}
                    >
                      <h6 className={`mx-1 my-0 `} >
                        {item?.sessionName}
                      </h6>
                    </Col>

                  ))}
                </Col>
                {listedTopics.length > 0 && (<Col className="d-flex flex-wrap">
                  <div className="d-flex align-items-center ">
                    Strands:
                  </div>

                  {listedTopics.map((item, index) => (
                    <a
                      key={index}
                      className={`d-flex align-items-center justify-content-start border-0 border-1 mx-0 px-0
                  
                  bg-${selectedTopic === item?.topicName ? "white" : "white"} 
                  text-${selectedTopic === item?.topicName ? "primary" : "warning"}
                  `}
                      href={`#${item}`}
                      onClick={() => { setListedSubTopics([]), setSelectedSubTopic(''), setListedContent([]), setSelectedTopic(item?.topicName) }}
                    >
                      <h6 className={`mx-1 my-0 font-size-10  d-flex d-md-none`} >
                        {item?.topicName}
                      </h6>
                      <h6 className={`mx-1 my-0  d-none d-md-flex`} >
                        {item?.topicName},
                      </h6>
                    </a>
                  ))}
                </Col>
                )}
                {selectedTopic && (
                  <Col xs={12} className="d-flex flex-wrap">
                    <div className="d-flex align-items-center ">
                      Sub Strands:
                    </div>

                    {listedSubTopics.map((item, index) => (
                      <a
                        key={index}
                        className={`d-flex align-items-center justify-content-start border-0 border-1 mx-0 px-0
                  
                  bg-${selectedSubTopic === item?.subTopicName ? "white" : "white"} 
                  text-${selectedSubTopic === item?.subTopicName ? "secondary" : "black"}
                  `}
                        href={`#${item}`}
                        onClick={() => setSelectedSubTopic(item?.subTopicName)}
                      >
                        <h6 className={`mx-1 my-0 font-size-10  d-flex d-md-none`} >
                          {item?.subTopicName}
                        </h6>
                        <h6 className={`mx-1 my-0  d-none d-md-flex`} >
                          {item?.subTopicName},
                        </h6>
                      </a>
                    ))}
                  </Col>
                )}
              </Row>

              {listedContent.length === 0 ? <div className="d-flex text-primary justify-content-center  my-5">
                {/* <h5>The are no files for this section</h5> */}
              </div> :
                <div className={`d-${showFileViewer ? "none" : "flex"} d-md-flex   flex-wrap col col-md-${showFileViewer ? "6" : "12"} pb-5 `}>
                  {listedContent.map((item, index) => (
                    <FileCard
                      key={index}
                      item={item}
                      downloadReady={downloadReady}
                      signTrigger={signTrigger
                      }
                      setSignTrigger={setSignTrigger}
                      setShowSignUpModal={prop.setShowSignUpModal}
                      setFileViewer={setShowFileViewer}
                      fileViewer={showFileViewer}
                      setSelected={setSelected}
                    />
                  ))}
                </div >}
            </div>}

          {showFileViewer && (

            <FileViewer
              selected={selected}
              setSelelected={setSelected}
              setFileViewer={setShowFileViewer}
              fileViewer={showFileViewer}

            />

          )}


        </Col>
      </Row>

      <Row
        className="bg-light mb-1">
        <Col
          md={8}
          lg={6}
          className="d-flex justify-content-between p-1">
          {fileTabs.map(((item, index) => (
            <div
              key={index}
              className={`d-flex align-items-center justify-content-center border-3 btn border-0 border-bottom btn-sm me-md-3 font-size-10 ps-1 pe-1
                  
                  bg-${activeFileTab?.name === item?.name ? "primary" : "white"} 
                  text-${activeFileTab?.name === item.name ? "white" : "primary"}
                  `}
              href={`#${item.name}`}
              onClick={() => setActiveFileTab({ name: item.name, value: item?.value })}
            >
              <i className={`${item?.icon} py-0 my-0 me-1 font-size-10`}></i>
              {/* <p className={`mx-1 my-0 py-0 `} > */}
              {item.name}
              {/* </p> */}
            </div>


          )))}
        </Col>
      </Row>
    </div>
  )
}

Home

export default Home