import PropTypes from 'prop-types';
import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

// Import menuDropdown
import LanguageDropdown from "components/CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "components/CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "components/CommonForBoth/TopbarDropdown/ProfileMenu";
import megamenuImg from "assets/images/megamenu-img.png";

import logo from "assets/images/Tehuti-Logo.png";
import logoLightSvg from "assets/images/Tehuti-Logo.png";

//i18n
import { withTranslation } from "react-i18next";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "store/actions";
import AuthPop from "pages/Authentication/AuthPop";


const Header = props => {
  const [search, setsearch] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false)




  const [userProfile, setUserProfile] = useState(null)

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }
  useEffect(() => {
    const profile = JSON.parse(localStorage.getItem("teutiUser"))
    setUserProfile(profile)
  }, [])
  useEffect(() => {
    const profile = JSON.parse(localStorage.getItem("teutiUser"))
    setUserProfile(profile)
  }, [props.updateLocalStorage])
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-2 font-size-16 header-item d-lg-none"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
            <div className="navbar-brand-boxd d-lg d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
              </Link>

              <Link to="/" className="logo ms-lg-5 logo-light d-flex align-items-center">
                <span className="d-none d-md-flex">
                  <img src={logoLightSvg} alt="" height="60" />
                </span>

              </Link>
              <div className=" d-flex d-md-none  mt-3 ">
                <Link to="/" className=" ">
                  <span className="logo-md">
                    <img src={logo} alt="" height="40" />
                  </span>
                </Link>
              </div>
            </div>
            <div className="d-md-flex d-none align-items-end ms-5 ">
              <h1 className="text-primary m-0 d-none d-lg-block ms-2 p-0 pb-1 fw-light">Supplementary  Content </h1>

            </div>


          </div>
          <div className="d-flex ">
            {/* <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search);
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div> */}
            {!userProfile && (
              <div className="d-flex justify-content-center align-items-center">
                <button className="btn btn-sm d-md-none btn-primary me-2"
                  onClick={() => { setShowSignUp(true), props.setShowSignUpModal(true) }}>
                  Register
                </button>
                <button className="btn d-none d-md-block btn-md btn-primary me-2"
                  onClick={() => { setShowSignUp(true), props.setShowSignUpModal(true) }}>
                  Register
                </button>
                <button className="btn btn-sm d-md-none btn-primary"
                  onClick={() => { setShowSignUp(false), props.setShowSignUpModal(true) }}>
                  Login
                </button>
                <button className="btn d-none d-md-block btn-md btn-primary"
                  onClick={() => { setShowSignUp(false), props.setShowSignUpModal(true) }}>
                  Login
                </button>
              </div>
            )}

            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon "
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>



            {userProfile && (<NotificationDropdown />)}


            {userProfile && (<ProfileMenu
              setUpdateLocalStorage={props.setUpdateLocalStorage}
              updateLocalStorage={props.updateLocalStorage} />)}

          </div>
        </div>
        <AuthPop
          showModal={props.showSignUpModal}
          setShowModal={props.setShowSignUpModal}
          showSignUp={showSignUp}
          setShowSignUp={setShowSignUp}
          setUpdateLocalStorage={props.setUpdateLocalStorage} />
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
  showSignUpModal: PropTypes.bool,
  setShowSignUpModal: PropTypes.func,
  setUpdateLocalStorage: PropTypes.func,
  updateLocalStorage: PropTypes.bool


};

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
