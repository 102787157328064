import React from 'react'
import PropTypes from 'prop-types'

const Register = props => {
  return (
    <div>Register</div>
  )
}

Register.propTypes = {}

export default Register